import { Component, OnInit } from '@angular/core';

import { SettingSection } from 'src/app/modules/setting/settings.base';
import { AccountService } from 'src/app/shared/services/account.service';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { IndicatorService } from 'src/app/shared/services/indicator.service';
import { UserManagementService } from 'src/app/shared/services/user-management.service';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss'],
})
export class AdminSettingsComponent extends SettingSection implements OnInit {
  breadCrumbData = [
    {
      name: 'Settings',
      link: '/setting/account',
    },
    {
      name: 'Admin Settings',
      link: '/setting/admin-settings',
    },
  ];

  readOnly = false;
  managerOptions: any[] = null;

  currentAccount: any = null;
  currentAccountType: string = null;
  currentAccountId: number = null;

  public first_account_manager: number = null;
  public second_account_manager: number = null;
  public sales_manager: number = null;
  public app_focused_enabled = false;

  constructor(
    public breadcrumbService: BreadcrumbService,
    public coreService: CoreService,
    public accountService: AccountService,
    public userService: UserManagementService,
    public indicator: IndicatorService,
  ) {
    super();

    if (this.coreService.settingsLocation === 'Account-Admin') {
      this.currentAccountId = this.coreService.editUserAccountId;
      this.currentAccountType = this.coreService.editAccountType;
    }
  }

  async ngOnInit() {
    this.breadcrumbService.updateBreadcrumbsData(
      this.breadCrumbData,
    );

    if (this.currentAccountId) {
      await this.fetchAccount();
    }

    this.fetchManagerOptions();
  }

  async fetchAccount() {
    this.currentAccount = await this.accountService
      .getAccount(this.currentAccountId, this.currentAccountType)
      .toPromise();

    // Update the manager option selections.
    this.first_account_manager = this.currentAccount?.account?.first_account_manager;
    this.second_account_manager = this.currentAccount?.account?.second_account_manager;
    this.sales_manager = this.currentAccount?.account?.sales_manager;
    if (this.currentAccount?.app_focused_enabled !== undefined) {
      this.app_focused_enabled = this.currentAccount?.app_focused_enabled;
    }
  }

  /*
    Retrives a list of all the potential managers.
  */
  async fetchManagerOptions() {
    this.managerOptions = await this.userService
      .getAllPlatformAdminUsers();
  }

  async handleOnSave() {
    // Use the existing account instance to build the payload.
    this.currentAccount.account.first_account_manager = this.first_account_manager;
    this.currentAccount.account.second_account_manager = this.second_account_manager;
    this.currentAccount.account.sales_manager = this.sales_manager;
    if (this.currentAccount?.app_focused_enabled !== undefined) {
      this.currentAccount.app_focused_enabled = this.app_focused_enabled;
    }

    const promise = this.accountService
      .updateAccount(
        this.currentAccountId, this.currentAccountType, this.currentAccount)
      .toPromise();

    await promise.then(
      this.indicator.successIndicator('Saved Successfully'),
      this.indicator.failureIndicator(),
    );
  }
}
